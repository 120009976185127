<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="lottieDivWrap" v-show="showHide" >
        <div class="position-absolute top-50 start-50 translate-middle">
          <div id="lottieDivs" class="m-auto"></div>
          <h2 v-if="title" class="mt-4 mb-1">{{title}}</h2>
          <p v-if="info">{{info}}</p>
          </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
<script>
import appConfig from "@/app.config";
import { EncryptStorage } from 'encrypt-storage';
export const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: appConfig.prefix,
});
export default {
  props: {
    path: {
      type: String,
      default: "animate/lottie.json",
    },
    title: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false
    },
    timer: {
      type: Number,
      default: 1500
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch:{
    showHide: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue)
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.showHide = newValue
        if (this.showHide){
            this.hide()
        }
       
      },
      deep: true
    }
    
  },
  data() {
    return {
      titlez: '',
      showHide: this.show
    }
  },
  mounted(){
    var svgContainer = document.getElementById('lottieDivs');
    var animation = window.bodymovin.loadAnimation({
      container: svgContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: this.path
    })
    animation.play()
   
  },
  created(){
    if (this.show){
      this.hide()
    }
  },
  methods:{
    hide() {

      var self = this
      if (this.timer==0){
        self.showHide = true
      }else{
        setTimeout(function(){  
          //self.$emit('closeeee', self.showHide)
          self.showHide = false
        }, this.timer)
      }
      
    },
    setData(){
     encryptStorage.setItem('token', 'edbe38e0-748a-49c8-9f8f-b68f38dbe5a2');
    // encryptStorage.setItem('token-not-encrypted', 'edbe38e0-748a-49c8-9f8f-b68f38dbe5a2', true);
    },
    clearData(){
      encryptStorage.clear()
    },
    getData(){
      const value = encryptStorage.getMultipleItems(['token', 'user', 'token-not-encrypted']);
      const value2 = encryptStorage.getItem('token');
      console.log(JSON.stringify(value))
      console.log(value2)
    },
    getPreviosLink(){
      return encryptStorage.getItem('pl');
    }
  }
};
</script>
<style scoped>
.lottieDivWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  background-color:#fff;
  top: 0px;
  z-index: 10;
  text-align: center;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
}
#lottieDivs {
  width: 100%;
  height: 50%;
}
</style>